import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export function ProfilePicture() {
  return (
    <StaticImage
      src="../../static/profile-picture.jpeg"
      alt="ChordChest"
      placeholder="blurred"
      layout="fixed"
      width={200}
      style={{ borderRadius: '50%' }}
    />
  )
}