import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export function AppStoreImageLink(props) {
  return (
    <a 
    href={props.href} 
    target="_blank"
    rel="noreferrer">
    <StaticImage
      src="../../static/app-store-black.png"
      alt="App Store"
      placeholder="blurred"
      layout="fixed"
      width={150}
    />
    </a>
  )
}