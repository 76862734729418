import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

function ChordChestLogo() {
  return (
    <StaticImage
      src="../../static/chordchest-logo.png"
      alt="ChordChest"
      placeholder="blurred"
      layout="fixed"
      width={200}
    />
  )
}

function ImageSearchLogo(props) {
  return (
    <StaticImage
      src="../../static/imagesearch-logo.png"
      alt="ImageSearch for Safari"
      placeholder="blurred"
      layout="fixed"
      width={200}
    />
  )
}

function SimplyJsonLogo(props) {
  return (
    <StaticImage
      src="../../static/simplyjson-logo.png"
      alt="SimplyJSON for Safari"
      placeholder="blurred"
      layout="fixed"
      width={200}
    />
  )
}

export { ChordChestLogo, ImageSearchLogo, SimplyJsonLogo}